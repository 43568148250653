<template>
  <v-container data-testid="fleet-menu-driver-information">
    <v-row>
      <v-col cols="12" class="pa-0">
        <v-card class="rounded-lg shadow-regular">
          <v-card-text>
            <v-container>
              <v-row>
                <v-col class="my-2 section-title py-0" cols="auto">
                  {{ t('Title_.subTitle.assignedDriver') }}
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6">
                  <RegularTextInput
                    :label="t('Msg_.driverInformation.firstName')"
                    data-testid="fleet-menu-driver-information-first-name-input"
                    disabled
                    :value="driverAssigned.firstName"
                  />
                </v-col>

                <v-col cols="6">
                  <RegularTextInput
                    :label="t('Msg_.driverInformation.lastName')"
                    data-testid="fleet-menu-driver-information-last-name-input"
                    disabled
                    :value="driverAssigned.lastName"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6" class="pt-0">
                  <RegularTextInput
                    :label="t('Msg_.driverInformation.phoneNumber')"
                    data-testid="fleet-menu-driver-information-phone-number-input"
                    disabled
                    :value="driverAssigned.phoneNumber"
                  />
                </v-col>

                <v-col cols="6" class="pt-0">
                  <RegularTextInput
                    :label="t('Msg_.driverInformation.email')"
                    data-testid="fleet-menu-driver-information-email-input"
                    disabled
                    :value="driverAssigned.email"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6" class="pt-0">
                  <RegularTextInput
                    :label="t('Msg_.driverInformation.address.street')"
                    data-testid="fleet-menu-driver-information-address-street-input"
                    disabled
                    :value="driverAddress.street || ''"
                  />
                </v-col>

                <v-col cols="3" class="pt-0">
                  <RegularTextInput
                    :label="t('Msg_.driverInformation.address.houseNumber')"
                    data-testid="fleet-menu-driver-information-address-housenumber-input"
                    disabled
                    :value="driverAddress.houseNumber || ''"
                  />
                </v-col>

                <v-col cols="3" class="pt-0">
                  <RegularTextInput
                    :label="t('Msg_.driverInformation.address.zipCode')"
                    data-testid="fleet-menu-driver-information-address-zipcode-input"
                    disabled
                    :value="driverAddress.zipCode || ''"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6" class="pt-0">
                  <RegularTextInput
                    :label="t('Msg_.driverInformation.address.city')"
                    data-testid="fleet-menu-driver-information-address-city-input"
                    disabled
                    :value="driverAddress.city || ''"
                  />
                </v-col>

                <v-col cols="6" class="pt-0">
                  <RegularTextInput
                    :label="t('Msg_.driverInformation.address.country')"
                    data-testid="fleet-menu-driver-information-address-country-input"
                    disabled
                    :value="driverAddress.country || ''"
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="px-0 py-4">
        <v-card class="rounded-lg py-2 shadow-regular">
          <v-card-title class="ml-3 section-title">
            {{ t('Title_.subTitle.assignmentTaxInformation') }}
          </v-card-title>
          <v-card-text class="pb-0">
            <v-container class="pb-0">
              <v-row>
                <v-col cols="6" class="pt-0">
                  <RegularTextInput
                    data-testid="fleet-menu-driver-information-tax-selection-input"
                    :label="t('Msg_.assignmentTaxInformation.taxSelection')"
                    :suffix="actualAssignment.taxSelectionType ? '' : '%'"
                    disabled
                    :value="taxSelectionType"
                  />
                </v-col>
                <v-col cols="6" class="pt-0">
                  <RegularTextInput
                    data-testid="fleet-menu-driver-information-salary-deduction-input"
                    :label="t('Msg_.assignmentTaxInformation.salaryDeduction')"
                    prefix="€"
                    disabled
                    :value="helpers.convertValueToCurrency(salaryDeduction)"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6" class="pt-0">
                  <RegularTextInput
                    data-testid="fleet-menu-driver-information-car-list-price-input"
                    :label="t('Msg_.assignmentTaxInformation.carListPrice')"
                    disabled
                    prefix="€"
                    :value="
                      helpers.convertValueToCurrency(
                        fleetVehiclesStore.vehicleDetails.basePrice
                      )
                    "
                  />
                </v-col>
                <v-col cols="6" class="pt-0">
                  <RegularTextInput
                    data-testid="fleet-menu-driver-information-distance-hub-input"
                    :label="t('Msg_.assignmentTaxInformation.distanceFromHub')"
                    :suffix="t('Msg_.km')"
                    disabled
                    :value="driverAddress.distanceFromMainHubInKms || 0"
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script setup>
import RegularTextInput from '../../shared/customComponents/inputs/regular-text-input.vue';
import languages from '../locales/languages';
import { useFleetVehiclesStore } from '@/store/pinia/fleet-vehicles.store.js';
import { useI18n } from 'vue-i18n-bridge';
import helpers from '@/helpers/helpers';
import { computed } from 'vue';

const fleetVehiclesStore = useFleetVehiclesStore();
const { t, tm } = useI18n({ locale: 'en', messages: languages });

// --- Computed ---
const salaryDeduction = computed(() => {
  const deduction =
    (actualAssignment.taxSelection / 100) *
    (driverAddress.distanceFromMainHubInKms || 0) *
    helpers.convertCurrencyToFloat(fleetVehiclesStore.vehicleDetails.basePrice);

  return isNaN(deduction) ? '' : deduction.toFixed(2);
});

const actualAssignment = computed(() => {
  return fleetVehiclesStore.vehicleDetails.actualAssignment;
});

const driverAssigned = computed(() => {
  return fleetVehiclesStore.vehicleDetails.actualAssignment.driver;
});

const driverAddress = computed(() => {
  return (
    fleetVehiclesStore.vehicleDetails.actualAssignment.driver.address || {}
  );
});

const taxSelectionType = computed(() => {
  if (actualAssignment.value.taxSelectionType) {
    const translation = helpers.convertStringToLowerCamelCase(
      '_',
      actualAssignment.value.taxSelectionType
    );
    return tm(`Action_.selectTaxType`)[translation];
  }
  return actualAssignment.value.taxSelection || '';
});
</script>
